import React from 'react';
import {Button, Col, Container, Jumbotron, Row} from 'react-bootstrap';
import {Field, Formik} from 'formik';
import {TOURS_PRAGUE_CZ} from '../shared/TOURS_PRAGUE_CZ';
import {TOURS_PRAGUE_EN} from '../shared/TOURS_PRAGUE_EN';
import {getLanguage, translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import {useToasts} from 'react-toast-notifications';
import * as Yup from 'yup';
import {isEnglishSet, metaTagsCZ, metaTagsEN} from '../shared/utils';
import GCalendar from '../components/gcalendar';
import DocumentMeta from 'react-document-meta';

const yesterday = new Date(Date.now() - 86400000);
const ReservationFormSchemaEN = Yup.object().shape({
  tour: Yup.string().required('Required field!'),
  date: Yup.date()
    .required('Required field!')
    .min(yesterday, 'Date of a walk cannot be in the past!'),
  adultsNumber: Yup.number().required('Required field!').min(1, 'Minimum is 1 adult'),
  childrenNumber: Yup.number().required('Required field!').min(0, 'Minimum is 0 children'),
  name: Yup.string().required('Required field!'),
  email: Yup.string().required('Required field!').email('Invalid email address'),
  notes: Yup.string().max(1000, 'Maximum length is 1000'),
});
const ReservationFormSchemaCZ = Yup.object().shape({
  tour: Yup.string().required('Povinné pole!'),
  date: Yup.date()
    .required('Povinné pole!')
    .min(yesterday, 'Datum procházky nemůže být v minulosti!'),
  adultsNumber: Yup.number().required('Povinné pole!').min(1, 'Minimum je 1 dospělý!'),
  childrenNumber: Yup.number().required('Povinné pole!').min(0, 'Minimum je 0 dětí!'),
  name: Yup.string().required('Povinné pole!'),
  email: Yup.string().required('Povinné pole!').email('Neplatná e-mailová adresa!'),
  notes: Yup.string().max(1000, 'Maximum je 1000 symbolů!'),
});

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}

class Reservation extends React.Component {
  render() {
    const {t, addToast} = this.props;
    const metaTags = isEnglishSet() ? metaTagsEN.reservation : metaTagsCZ.reservation;
    window.scrollTo(0, 0);
    return (
      <DocumentMeta {...metaTags}>
        <Container className="mt-5 mb-5 pt-5 pb-5">
          <h3>{t('pages.reservation.title')}</h3>
          <Row>
            <Col xs={12} lg={6}>
              <Jumbotron>
                <GCalendar />
              </Jumbotron>
            </Col>
            <Col xs={12} lg={6}>
              <Jumbotron>
                <Formik
                  initialValues={{
                    tour: '',
                    date: '',
                    adultsNumber: '',
                    childrenNumber: '',
                    name: '',
                    email: '',
                    notes: '',
                  }}
                  validationSchema={
                    isEnglishSet() ? ReservationFormSchemaEN : ReservationFormSchemaCZ
                  }
                  onSubmit={(values, {resetForm}) => {
                    sendMail(
                      values.tour,
                      values.date,
                      values.adultsNumber,
                      values.childrenNumber,
                      values.name,
                      values.email,
                      values.notes
                    ).then((r) => {
                      if (r.ok) {
                        console.log('success');
                        let text = '';
                        if (isEnglishSet()) {
                          text =
                            'The reservation was successfully sent! You will get an e-mail in few minutes.';
                        } else {
                          text =
                            'Rezervace byla úspěšně odeslána. Během chvilky Vám přijde potvrzovací e-mail.';
                        }
                        addToast(text, {appearance: 'success'});
                      } else {
                        console.log('fail');
                        let text = '';
                        if (isEnglishSet()) {
                          text =
                            "Reservation wasn't sent due to unknown problems. Please try to refresh the page and create a reservation again.";
                        } else {
                          text =
                            'Nepodařilo se odeslat rezervaci. Prosím zkuste aktualizovat stránku a vytvořit rezervaci znovu.';
                        }
                        addToast(text, {appearance: 'error'});
                      }
                    });
                    resetForm({});
                  }}>
                  {({values, handleSubmit, errors, touched}) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>{t('pages.reservation.form.tour')}</label>
                        <Field
                          className="form-control select-dropdown"
                          as="select"
                          name="tour"
                          value={values.tour}>
                          {isEnglishSet() ? (
                            <>
                              <option value="" disabled selected>
                                Choose a tour
                              </option>
                              {/*<optgroup label="Procházky po Praze"></optgroup>*/}
                              {TOURS_PRAGUE_EN.map((tour, i) => {
                                return <option value={tour.name}>{tour.name}</option>;
                              })}
                              {/*<optgroup label="Ostatní procházky"></optgroup>*/}
                              {/*{TOURS_OTHERS_EN.map((tour, i) => {*/}
                              {/*    return (<option value={tour.name}>{tour.name}</option>)*/}
                              {/*})}*/}
                            </>
                          ) : (
                            <>
                              <option value="" disabled selected>
                                Vyberte procházku
                              </option>
                              {/*<optgroup label="Procházky po Praze"></optgroup>*/}
                              {TOURS_PRAGUE_CZ.map((tour, i) => {
                                return (
                                  <option key={tour.name} value={tour.name}>
                                    {tour.name}
                                  </option>
                                );
                              })}
                              {/*<optgroup label="Ostatní procházky"></optgroup>*/}
                              {/*{TOURS_OTHERS_CZ.map((tour, i) => {*/}
                              {/*    return (<option value={tour.name}>{tour.name}</option>)*/}
                              {/*})}*/}
                            </>
                          )}
                        </Field>
                        {errors.tour && touched.tour ? (
                          <div className="error-note">{errors.tour}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>{t('pages.reservation.form.date')}</label>
                        <Field
                          className="form-control"
                          type="date"
                          name="date"
                          value={values.date}
                        />
                        {errors.date && touched.date ? (
                          <div className="error-note">{errors.date}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>{t('pages.reservation.form.adults_number')}</label>
                        <Field
                          className="form-control"
                          type="number"
                          min="0"
                          name="adultsNumber"
                          value={values.adultsNumber}
                        />
                        {errors.adultsNumber && touched.adultsNumber ? (
                          <div className="error-note">{errors.adultsNumber}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>{t('pages.reservation.form.children_number')}</label>
                        <Field
                          className="form-control"
                          type="number"
                          min="0"
                          name="childrenNumber"
                          value={values.childrenNumber}
                        />
                        {errors.childrenNumber && touched.childrenNumber ? (
                          <div className="error-note">{errors.childrenNumber}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>{t('pages.reservation.form.name')}</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="name"
                          value={values.name}
                        />
                        {errors.name && touched.name ? (
                          <div className="error-note">{errors.name}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>{t('pages.reservation.form.email')}</label>
                        <Field
                          className="form-control"
                          type="email"
                          name="email"
                          value={values.email}
                        />
                        {errors.email && touched.email ? (
                          <div className="error-note">{errors.email}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>{t('pages.reservation.form.notes')}</label>
                        <Field
                          className="form-control"
                          as="textarea"
                          name="notes"
                          value={values.notes}
                        />
                        {errors.notes && touched.notes ? (
                          <div className="error-note">{errors.notes}</div>
                        ) : null}
                      </div>
                      <Button variant="primary" type="submit" className="float-right">
                        {t('pages.reservation.form.submit')}
                      </Button>
                    </form>
                  )}
                </Formik>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
      </DocumentMeta>
    );
  }
}

export async function sendMail(tourName, date, adults, children, name, email, notes) {
  const lang = getLanguage();
  const formData = new FormData();
  formData.append('tour', tourName);
  formData.append('date', date);
  formData.append('adults', adults);
  formData.append('children', children);
  formData.append('name', name);
  formData.append('email', email);
  formData.append('lang', lang);
  formData.append('notes', notes);
  return await fetch('https://blankahladka.cz/backend/sendmail.php', {
    method: 'POST',
    body: formData,
  });
}

Reservation.propTypes = {
  t: PropTypes.func.isRequired,
  addToast: PropTypes.func,
};

export default translate(withToast(Reservation));
