import React from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {isEnglishSet} from '../shared/utils';

class RowWithPicture extends React.Component {
  render() {
    const {t, data} = this.props;
    const rowClass = data.data.reversed ? 'row-reverse' : '';
    const moreAboutBlankaText = isEnglishSet() ? 'More about Blanka' : 'Více o Blance';
    return (
      <Row className={`mt-5 ${rowClass}`}>
        <Col xs={12} md={6} className="text-justify">
          <h5>{data.data.title}</h5>
          {data.data.text}
          {data.data.id === 3 ? <Link to="/about"> {moreAboutBlankaText}</Link> : ''}
        </Col>
        <Col xs={12} md={6}>
          <Image className="row-card-image" src={data.image} alt={data.data.title} fluid />
        </Col>
      </Row>
    );
  }
}

RowWithPicture.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default translate(RowWithPicture);
