import React from 'react';
import {Container, Jumbotron, Table} from 'react-bootstrap';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import {TOURS_PRAGUE_EN} from '../shared/TOURS_PRAGUE_EN';
import {TOURS_PRAGUE_CZ} from '../shared/TOURS_PRAGUE_CZ';
import {TOURS_OTHERS_EN} from '../shared/TOURS_OTHERS_EN';
import {TOURS_OTHERS_CZ} from '../shared/TOURS_OTHERS_CZ';
import {isEnglishSet, metaTagsCZ, metaTagsEN} from '../shared/utils';
import DocumentMeta from 'react-document-meta';

class Prices extends React.Component {
  render() {
    const {t} = this.props;
    const metaTags = isEnglishSet() ? metaTagsEN.prices : metaTagsCZ.prices;
    const PRAGUE_TOURS = isEnglishSet() ? TOURS_PRAGUE_EN : TOURS_PRAGUE_CZ;
    const OTHER_TOURS = isEnglishSet() ? TOURS_OTHERS_EN : TOURS_OTHERS_CZ;
    window.scrollTo(0, 0);
    return (
      <DocumentMeta {...metaTags}>
        <Container className="mt-5 mb-5 pt-5 pb-5">
          <Jumbotron>
            <b>{t('pages.prices.individual.title')}</b>
            <br />
            {t('pages.prices.individual.legend')}
          </Jumbotron>
          <h3>
            {isEnglishSet()
              ? 'Price list of the Prague private tours for 2 persons'
              : 'Ceník soukromých pražských procházek pro 2 osoby'}
          </h3>
          <Table className="rounded-table" striped bordered hover responsive>
            <thead>
              <tr>
                <th>{t('widgets.tours_table.name')}</th>
                <th>{t('widgets.tours_table.objects')}</th>
                <th>{t('widgets.tours_table.included')}</th>
                <th>{t('widgets.tours_table.price')}</th>
              </tr>
            </thead>
            <tbody>
              {PRAGUE_TOURS.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{value.name}</td>
                    <td>{value.description === '' ? value.legend : value.description}</td>
                    <td>{value.restrictions}</td>
                    <td>
                      {value.price}/{value.duration}h
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <h3 className="mt-3">
            {isEnglishSet()
              ? 'Price list of the outside of Prague private tours for 2 persons'
              : 'Ceník soukromých mimopražských procházek pro 2 osoby'}
          </h3>
          <Table className="rounded-table" striped bordered hover responsive>
            <thead>
              <tr>
                <th>{t('widgets.tours_table.name')}</th>
                <th>{t('widgets.tours_table.objects')}</th>
                <th>{t('widgets.tours_table.included')}</th>
                <th>{t('widgets.tours_table.price')}</th>
              </tr>
            </thead>
            <tbody>
              {OTHER_TOURS.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{value.name}</td>
                    <td>{value.description === '' ? value.legend : value.description}</td>
                    <td>{value.restrictions}</td>
                    {/*<td>*/}
                    {/*  {value.price}/{value.duration}h*/}
                    {/*</td>*/}
                    <td>
                      {isEnglishSet() ? 'by appointment' : 'dle dohody'}/{value.duration}h
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Jumbotron className="mt-3">
            <b>{t('pages.prices.group.title')}</b>
            <br />
            {t('pages.prices.group.legend')}
          </Jumbotron>
          <Jumbotron>
            <b>{t('pages.prices.tourOfTheMonth.title')}</b>
            <br />
            {t('pages.prices.tourOfTheMonth.legend')}
            <br />
            <b>{t('pages.prices.tourOfTheMonth.price')}</b>
          </Jumbotron>
          <h3>{isEnglishSet() ? 'Personalised tours' : 'Procházky na míru'}</h3>
          {t('pages.personalised.pricesNote')}
          <br />
          <br />
          <Jumbotron>
            <b>{t('pages.personalised.types.individual.title')}</b>
            <br />
            {t('pages.personalised.types.individual.legend')}
            <br />
            <b>{t('pages.personalised.types.individual.price')}</b>
          </Jumbotron>
          <Jumbotron>
            <b>{t('pages.personalised.types.family.title')}</b>
            <br />
            {t('pages.personalised.types.family.legend')}
            <br />
            <b>{t('pages.personalised.types.family.price')}</b>
          </Jumbotron>
          <Jumbotron>
            <b>{t('pages.personalised.types.family2.title')}</b>
            <br />
            {t('pages.personalised.types.family2.legend')}
            <br />
            <b>{t('pages.personalised.types.family2.price')}</b>
          </Jumbotron>
          <Jumbotron>
            <b>{t('pages.personalised.types.group.title')}</b>
            <br />
            {t('pages.personalised.types.group.legend')}
          </Jumbotron>
        </Container>
      </DocumentMeta>
    );
  }
}

Prices.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(Prices);
