export const TOURS_PRAGUE_EN = [
  {
    id: '1',
    name: 'Basic Prague',
    legend: 'Shorter version of the Classical Prague tour.',
    description:
      'You will see the Old Town, the Jewish Town, the Charles bridge and the Prague castle.',
    objects: '',
    restrictions:
      'A short coffee break is included in the tour.\nPublic transport tickets and interior tickets are not included in the price.',
    images: 'assets/09.jpg',
    duration: '4',
    price: '1600 CZK',
    type: 'classic',
    meetingPoint: 'NY',
  },
  {
    id: '2',
    name: 'Classical Prague',
    legend: 'The Prague tour with a lunch and a boat cruise.',
    description:
      'You will see the Old Town, the Jewish town, the Charles bridge and the Prague castle and you will enjoy a boat cruise.',
    objects: '',
    restrictions:
      'Both (the boat cruise with a drink and the lunch) are not included in the price.\nPublic transport tickets and interior tickets are not included in the price.',
    images: 'assets/09.jpg',
    duration: '6',
    price: '2400 CZK',
    type: 'classic',
    meetingPoint: 'NY',
  },
  {
    id: '3',
    name: 'The New Town of Prague',
    legend: 'A walk around the New Town.',
    description:
      'This tour includes the Wenceslas square, the Lucerna palace, the Franciscan garden, hidden streets of the New Town, the Dancing house and the Charles square.',
    objects: '',
    restrictions:
      'A lunch break is included in the tour.\nPublic transport tickets are not included in the price.',
    images: '09.jpg',
    duration: '5',
    price: '2000 CZK',
    type: 'undiscovered',
    meetingPoint: 'VN',
  },
  {
    id: '4',
    name: 'The Old Town',
    legend: 'A walk around the Old Town.',
    description:
      'You will see the Old Town square, the Prague Astronomical Clock, the Týn church, the St. Nicholas church, Ungelt, the St. James church, Old Town streets and the St. Agnes convent.',
    objects: '',
    restrictions: 'Public transport tickets are not included in the price.',
    images: '../assets/09.jpg',
    duration: '2',
    price: '800 CZK',
    type: 'classic',
    meetingPoint: 'NY',
  },
  {
    id: '5',
    name: 'The Prague castle without interiors',
    legend: 'A walk around the Prague castle without interior visit.',
    description:
      'We will see the Saint Vitus cathedral, the Old Royal palace and the St. George basilica.',
    objects: '',
    restrictions: 'Public transport tickets and interior tickets are not included in the price.',
    images: '../assets/09.jpg',
    duration: '2',
    price: '800 CZK',
    type: 'classic',
    meetingPoint: 'MS',
  },
  {
    id: '6',
    name: 'The Prague castle with interiors',
    legend: 'A walk around the Prague castle with interior visit.',
    description:
      'A walk around the Prague castle with interior visit. We will visit the Saint Vitus cathedral, the Old Royal palace, the St. George basilica and the Golden lane.',
    objects: '',
    restrictions:
      'Public transport tickets, the interior tickets and the exhibition "The story of the Prague castle" are not included in the price.',
    images: '../assets/09.jpg',
    duration: '4',
    price: '1600 CZK',
    type: 'classic',
    meetingPoint: 'MS',
  },
  {
    id: '7',
    name: 'Vyšehrad',
    legend: 'A walk around the second oldest fortress in Prague.',
    description:
      'The tour includes a visit of the St. Peter and Paul church, Slavín - the famous Czechs cemetery, some of the most beautiful views in Prague and we can visit the Gorlice hall, where some of the original Charles bridge statues are hidden.',
    objects: '',
    restrictions:
      'The tickets to the st. Peter and Paul church are not included in the price.\nPublic transport tickets are not included in the price.',
    images: '../assets/09.jpg',
    duration: '2',
    price: '800 CZK',
    type: 'classic',
    meetingPoint: 'VN',
  },
  {
    id: '8',
    name: 'The Smaller Town (Malá Strana district) and the Strahov monastery',
    legend: 'A walk around The Smaller Town and the Strahov monastery.',
    description:
      'You will see Pohořelec, the Strahov library, views over Prague and some of the Smaller Town churches, gardens and palaces.',
    objects: '',
    restrictions:
      'The Strahov library tickets are not included in the price.\nPublic transport tickets are not included in the price.',
    images: '../assets/09.jpg',
    duration: '3',
    price: '1200 CZK',
    type: 'undiscovered',
    meetingPoint: 'MS',
  },
  {
    id: '9',
    name: 'The Jewish Town without interiors',
    legend: 'A walk around The Jewish Town.',
    description:
      'The tour includes a walk around the Prague Jewish Town and its most famous synagogues (the Old-New synagogue, the Klausen synagogue, the Pinkas synagogue etc.).',
    objects: '',
    restrictions: 'Public transport tickets and interior tickets are not included in the price.',
    images: '../assets/09.jpg',
    duration: '2',
    price: '800 CZK',
    type: 'classic',
    meetingPoint: 'NY',
  },
  {
    id: '10',
    name: 'The Jewish Town with interiors',
    legend: 'A walk around The Jewish Town including a visit of its most famous objects.',
    description:
      'The tour includes a walk around the Prague Jewish Town and a visit of its most famous synagogues (the Old-New synagogue, the Klausen synagogue, the Pinkas synagogue etc.) and a walk through the Old jewish cemetery.',
    objects: '',
    restrictions:
      'The interior tickets are not included in the price.\nPublic transport tickets are not included in the price.',
    images: '../assets/09.jpg',
    duration: '3',
    price: '1200 CZK',
    type: 'classic',
    meetingPoint: 'NY',
  },
  {
    id: '11',
    name: 'The Castle Town and the New world district (Nový svět)',
    legend:
      'A walk around The Castle Town and the New world district with a visit of the Prague Loretto.',
    description: '',
    objects: '',
    restrictions:
      'The entry tickets to the Loretto are not included in the price.\nPublic transport tickets are not included in the price.',
    images: '../assets/09.jpg',
    duration: '2',
    price: '800 CZK',
    type: 'undiscovered',
    meetingPoint: 'MS',
  },
  {
    id: '12',
    name: 'Modern Prague',
    legend:
      'Prague architecture from between the end of the 19th and the beginning of the 21th century.',
    description: '',
    objects: '',
    restrictions: 'Public transport tickets are not included in the price.',
    images: '../assets/09.jpg',
    duration: '4',
    price: '1600 CZK',
    type: 'thematic',
    meetingPoint: 'VN',
  },
  {
    id: '13',
    name: 'The Naughty Prague',
    legend: 'This walk is unsuitable for children.',
    description:
      'A walk through the streets of the Old and Jewish town followed by commentary about the life and work of the Prague street girls and about the most famous, most popular, and even the worst Prague brothels. The tour includes two murder stories and a few royal scandals.',
    objects: '',
    restrictions:
      'The tour starts by the New Yorker shop at the Na Můstku street. Public transport tickets are not included in the price.',
    images: '../assets/09.jpg',
    duration: '3',
    price: '1200 CZK',
    type: 'thematic',
    meetingPoint: 'NY',
  },
  {
    id: '14',
    name: '"About the Vyšehrad ghosts, the jumping horse and the Devil\'s column"',
    legend: 'Tour for families with kids.',
    description:
      "A walk tracing the steps of the most famous Vyšehrad ghosts including a visit of the site from where the legendary horse Šemík jumped and where the princess Libuše had her dream about establishment of Prague. We will see the famous Devil's column and the bravest may try to touch it. Then we will try to find the saint Wenceslas, who is not on the Wenceslas square.",
    objects: '',
    restrictions:
      'This tour is suitable for children of all ages and it lasts two hours. Public transport tickets are not included in the price.',
    images: '../assets/09.jpg',
    duration: '2',
    price: '800 CZK',
    type: 'children',
    meetingPoint: 'VN',
  },
  {
    id: '15',
    name: 'Specific tours',
    legend: 'In case of interest only.',
    description: 'The Prague ZOO, The summerhouse Hvězda, The Břevnov monastery, etc.',
    objects: '',
    restrictions: '',
    images: '../assets/09.jpg',
    duration: 'depends on a type of the walk',
    price: 'depends on a type of the walk',
  },
  {
    id: '16',
    name: 'In the footsteps of Charles IV',
    legend:
      'Entertaining afternoon in Prague with children? Just say a word and we can start walking In the footsteps of Charles IV.! For two hours your children will become detectives, fulfilling the requested tasks. The one who gets the most points wins a deserved award!',
    description:
      'The tour starts by the statue of Charles IV. at the Křižovnické square, from here we follow the Karlova street, turn left to the Mariánské square, we will visit the Old Town square, walk through the Železná street to the Charles university and from here we continue to the Na Příkopě street, where the tour ends. During the tour the little detectives fulfill different tasks and the winner receives deserved reward.',
    objects: '---',
    restrictions: 'Children under six years of age join the tour for free',
    images: '---',
    duration: '2',
    price: '400 CZK/person',
    type: 'children',
    meetingPoint: 'KN',
  },
  {
    id: '17',
    name: 'Stromovka - the Royal park',
    legend: '---',
    description: '---',
    objects: '---',
    restrictions: '---',
    images: '---',
    duration: '---',
    price: '---',
    type: 'children',
    meetingPoint: 'VN',
  },
];
