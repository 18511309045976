import React from 'react';
import {Col, Image, Jumbotron, Row} from 'react-bootstrap';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import {TOURS_PRAGUE_CZ} from '../shared/TOURS_PRAGUE_CZ';
import {TOURS_PRAGUE_EN} from '../shared/TOURS_PRAGUE_EN';
import OldTownSquare18 from '../assets/images/prague/OldTownSquare18_sm.jpg';
import {Link} from 'react-router-dom';
import {isEnglishSet} from '../shared/utils';

class TourOfTheMonthCard extends React.Component {
  render() {
    const {t, showLink} = this.props;

    const tourOfTheMonthData = isEnglishSet() ? TOURS_PRAGUE_EN[12] : TOURS_PRAGUE_CZ[12];
    const tourOfTheMonthImage = OldTownSquare18;

    const today = new Date();
    const monthCz = today.toLocaleString('cs-CZ', {month: 'long'});
    const monthEn = today.toLocaleString('en-GB', {month: 'long'});

    const month = isEnglishSet() ? monthEn : monthCz;
    const day = isEnglishSet() ? 'Saturday' : 'Sobota';
    const time = '14:30';
    return (
      <Jumbotron className="mt-4 tour-of-the-month">
        <Row className="align-items-center">
          <Col xs={12} md={6} lg={7}>
            <h5 className="tour-of-the-month-title">{month.toUpperCase()} {today.getFullYear()}</h5>
            {t('tourOfTheMonth.legend')} {month}:
            <br />
            <br />
            <span className="font-weight-bold">{tourOfTheMonthData.name}</span>
            <br />
            {t('tourOfTheMonth.day')} {day}
            <br />
            {t('tourOfTheMonth.time')} {time}
            <br />
            {t('tourOfTheMonth.duration')} {tourOfTheMonthData.duration}h
            <br />
            {t('tourOfTheMonth.meetingPoint')} {tourOfTheMonthData.meetingPoint}
            <br />
            {t('tourOfTheMonth.price')} {tourOfTheMonthData.price}
            <br />
            <br />
            {showLink ? <Link to="/tour-of-the-month">{t('tourOfTheMonth.link')}</Link> : ''}
            {!showLink ? <Link to="/reservation">{t('pages.tourOfTheMonth.reserve')}</Link> : ''}
          </Col>
          <Col xs={12} md={6} lg={5}>
            <Image src={tourOfTheMonthImage} className="row-card-image" fluid />
          </Col>
        </Row>
      </Jumbotron>
    );
  }
}

TourOfTheMonthCard.propTypes = {
  t: PropTypes.func.isRequired,
  showLink: PropTypes.bool,
};

export default translate(TourOfTheMonthCard);
