import React from 'react';
import {Container, Fade, Tab, Tabs} from 'react-bootstrap';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLockOpen, faUserLock} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import {isEnglishSet} from '../shared/utils';

class TourTypesTabs extends React.Component {
  render() {
    const {t} = this.props;
    const tab1Legend = isEnglishSet() ? (
      <Container className="pt-2 tour-tab-content">
        Procházka, které se zúčastníte jenom vy a váš doprovod, popř. vaše rodina či skupina. Můžete
        si vybrat buď ze <Link to="/tours-prague-classic">stálé nabídky procházek zde</Link> nebo si
        vytvořit vaši procházku přímo <Link to="/tours-personalised">na míru zde</Link>. Procházku
        je potřeba si objednat předem.
        <br />
        <br />
        <Link to="/reservation">Book a Private tour</Link>
      </Container>
    ) : (
      <Container className="pt-2 tour-tab-content">
        Procházka, které se zúčastníte jenom vy a váš doprovod, popř. vaše rodina či skupina. Můžete
        si vybrat buď ze <Link to="/tours-prague-classic">stálé nabídky procházek zde</Link> nebo si
        vytvořit vaši procházku přímo <Link to="/tours-personalised">na míru zde</Link>. Procházku
        je potřeba si objednat předem.
        <br />
        <br />
        <Link to="/reservation">Chci si objednat soukromou procházku</Link>
      </Container>
    );
    const tab2Legend = isEnglishSet() ? (
      <Container className="pt-2 tour-tab-content">
        Otevřená skupinová procházka pro kohokoli, kdo se předem přihlásí nebo aktuálně na místě
        srazu připojí. Kapacita je maximálně 12 účastníků. Otevřená skupinová procházka je cenově
        výhodnější než procházka soukromá.
        <br />
        <br />
        <Link to="/reservation">I want to book the "Tour of the month"</Link>
      </Container>
    ) : (
      <Container className="pt-2 tour-tab-content">
        Otevřená skupinová procházka pro kohokoli, kdo se předem přihlásí nebo aktuálně na místě
        srazu připojí. Kapacita je maximálně 12 účastníků. Otevřená skupinová procházka je cenově
        výhodnější než procházka soukromá.
        <br />
        <br />
        <Link to="/reservation">Chci se objednat na skupinovou "Procházku měsíce"</Link>
      </Container>
    );
    const tab1Title = (
      <>
        <FontAwesomeIcon icon={faUserLock} /> {t('widgets.tourTypesTabs.tab1.title')}
      </>
    );
    const tab2Title = (
      <>
        <FontAwesomeIcon icon={faLockOpen} /> {t('widgets.tourTypesTabs.tab2.title')}
      </>
    );
    return (
      <Container className="mt-4">
        <Tabs defaultActiveKey="private" transition={Fade} id="tour-types">
          <Tab eventKey="private" title={tab1Title}>
            {tab1Legend}
          </Tab>
          <Tab eventKey="public" title={tab2Title}>
            {tab2Legend}
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

TourTypesTabs.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(TourTypesTabs);
