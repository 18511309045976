export const TOURS_OTHERS_EN = [
  {
    id: '1',
    name: 'Český krumlov',
    legend: 'Celodenní výlet do jednoho z nejkrásnějších českých měst.',
    description:
      'Součástí procházky je návštěva historického centra města a návštěva krumlovského zámku (nezahrnuje interiéry).',
    objects: '',
    restrictions:
      'V ceně je zahrnuta cesta autobusem nebo autem (záleží na velikosti skupiny), rozchod na oběd.\nV ceně není zahrnuté vstupné do hradu ani do věže.',
    images: 'assets/09.jpg',
    duration: '10',
    price: '1600 CZK',
  },
  {
    id: '2',
    name: 'Terezín',
    legend: 'Návštěva nejznámějšího bývalého koncentračního tábora v Čechách.',
    description: '',
    objects: '',
    restrictions:
      'Do ceny je zahrnuta cesta autobusem nebo autem (záleží na velikosti skupiny), návštěva Malé pevnosti a Muzea ghetta, přestávka na svačinu.',
    images: '',
    duration: '6',
    price: '',
  },
  {
    id: '3',
    name: 'Plzeňský pivovar',
    legend: 'Návštěva Plzeňského pivovaru, spojená s ochutnávkou piva.',
    description: '',
    objects: '',
    restrictions:
      'Do ceny je zahrnuta cesta autobusem nebo autem (záleží na velikosti skupiny), vstupné do pivovaru s ochutnávkou piva a přestávka na oběd.',
    images: '',
    duration: '6',
    price: '',
  },
  {
    id: '4',
    name: 'Plzeňský pivovar',
    legend: 'Zkrácená verze předchozí procházky.',
    description: '',
    objects: '',
    restrictions:
      'Do ceny je zahrnuta cesta autobusem nebo autem (záleží na velikosti skupiny) a vstupné do pivovaru s ochutnávkou piva.\nV ceně není zahrnutá přestávka na oběd.',
    images: '',
    duration: '5',
    price: '',
  },
  {
    id: '5',
    name: 'Plzeňský pivovar a sklárna Nižbor',
    legend: 'Návštěva sklárny v Nižboru a Plzeňského pivovaru v jednom dni.',
    description: '',
    objects: '',
    restrictions:
      'Do ceny je zahrnuta cesta autobusem nebo autem (záleží na velikosti skupiny) a přestávka v nižborské firemní prodejně, vstupné do Plzeňského pivovaru s ochutnávkou piva, přestávka na oběd v Plzni.',
    images: '',
    duration: '7',
    price: '',
  },
  {
    id: '6',
    name: 'Kutná hora',
    legend:
      'Výlet do nejznámějšího bývalého centra těžby stříbra a ražby stříbrných mincí v Čechách.',
    description:
      'Tato procházka zahrnuje návštěvu historického centra Kutné hory a Sedlecké Kostnice.',
    objects: '',
    restrictions:
      'Do ceny je zahrnuta cesta autobusem nebo autem (záleží na velikosti skupiny), vstupné do Sedlecké Kostnice a přestávka na oběd.\nDo ceny není zahrnuta návštěva interiérů (s výjimkou Kostnice).',
    images: '',
    duration: '6',
    price: '',
  },
  {
    id: '7',
    name: 'Kutná hora',
    legend: 'Rozšířená verze předchozí procházky.',
    description:
      'Tato procházka zahrnuje návštěvu historického centra Kutné hory a Sedlecké Kostnice.',
    objects: '',
    restrictions:
      'Do ceny je zahrnuta cesta autobusem nebo autem (záleží na velikosti skupiny), vstupné do Sedlecké Kostnice a přestávka na oběd.\nCena navíc zahrnuje vstupné do katedrály sv. Barbory a vstupné do Vlašského dvora.',
    images: '',
    duration: '7',
    price: '',
  },
  {
    id: '8',
    name: 'Kutná hora a Kolín',
    legend:
      'Výlet do Kutné hory s návštěvou Kostnice a města, následovaný návštěvou Kolína s bývalou druhou největší židovskou čtvrtí v Čechách.',
    description: 'Uvidíte Sedleckou Kostnici, Kolínskou synagogu, Starý židovský hřbitov.',
    objects: '',
    restrictions:
      'Do ceny je zahrnuta cesta autobusem nebo autem (záleží na velikosti skupiny), krátká návštěva historického centra Kutné hory a vstupné do Sedlecké Kostnice.',
    images: '',
    duration: '7',
    price: '',
  },
  {
    id: '9',
    name: 'Sklárna Nižbor',
    legend:
      'Návštěva sklárny v Nižboru spojená se shlédnutím výroby a výzdoby skla, možnost nákupu ve firemní prodejně.',
    description: '',
    objects: '',
    restrictions:
      'Do ceny je zahrnuta cesta autobusem nebo autem (záleží na velikosti skupiny) a přestávka v nižborské firemní prodejně.',
    images: '',
    duration: '4',
    price: '',
  },
  {
    id: '10',
    name: 'Sklárna Nižbor a Plzeňský pivovar',
    legend: 'Návštěva sklárny v Nižboru a Plzeňského pivovaru v jednom dni.',
    description: '',
    objects: '',
    restrictions:
      'Do ceny je zahrnuta cesta autobusem nebo autem (záleží na velikosti skupiny) a přestávka v nižborské firemní prodejně, návštěva Plzeňského pivovaru s ochutnávkou, oběd v Plzni.',
    images: '',
    duration: '7',
    price: '',
  },
];
