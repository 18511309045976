import {getLanguage} from 'react-switch-lang';

export const isEnglishSet = () => {
  return getLanguage() === 'en';
};

export const metaTagsEN = {
  about: {
    title: 'About Blanka',
    description: 'About Blanka Hladká',
    canonical: 'https://prahousblankou.cz/#/about',
  },
  homePage: {
    title: 'Blanka Hladká',
    description: 'Blanka Hladká - Prague tour guide.',
    canonical: 'https://prahousblankou.cz',
  },
  journal: {
    title: 'Journal',
    description: 'Blanka Hladká - Journal.',
    canonical: 'https://prahousblankou.cz/#/journal',
  },
  otherTours: {
    title: 'Outside of Prague Tours',
    description: 'Blanka Hladká - Outside of Prague Tours.',
    canonical: 'https://prahousblankou.cz/#/tours-other',
  },
  personalisedTours: {
    title: 'Personalized Prague tours',
    description: 'Blanka Hladká - Personalized Prague tours',
    canonical: 'https://prahousblankou.cz/#/tours-personalised',
  },
  pragueClassicalTours: {
    title: 'Classical Prague Tours',
    description: 'Blanka Hladká - Classical Prague Tours.',
    canonical: 'https://prahousblankou.cz/#/tours-prague-classic',
  },
  pragueForChildrenTours: {
    title: 'Prague for children',
    description: 'Blanka Hladká - Prague for children.',
    canonical: 'https://prahousblankou.cz/#/tours-prague-for-children',
  },
  pragueThematicTours: {
    title: 'Thematic Prague tours',
    description: 'Blanka Hladká - Thematic Prague tours',
    canonical: 'https://prahousblankou.cz/#/tours-prague-thematic',
  },
  pragueUndiscoveredTours: {
    title: 'Undiscovered Prague',
    description: 'Blanka Hladká - Undiscovered Prague.',
    canonical: 'https://prahousblankou.cz/#/tours-prague-undiscovered',
  },
  preparation: {
    title: 'Preparation for the tour',
    description: 'Blanka Hladká - Preparation for the tour.',
    canonical: 'https://prahousblankou.cz/#/preparation',
  },
  prices: {
    title: 'Price of the tours',
    description: 'Blanka Hladká - Price of the tours.',
    canonical: 'https://prahousblankou.cz/#/prices',
  },
  reservation: {
    title: 'Book a tour',
    description: 'Blanka Hladká - Book a tour.',
    canonical: 'https://prahousblankou.cz/#/reservation',
  },
  reviews: {
    title: 'Reviews',
    description: 'Blanka Hladká - Reviews.',
    // canonical: 'https://prahousblankou.cz/#/tours-prague-classic',
  },
  tourOfTheMonth: {
    title: 'Tour of the month',
    description: 'Blanka Hladká - Tour of the month.',
    canonical: 'https://prahousblankou.cz/#/tour-of-the-month',
  },
};
export const metaTagsCZ = {
  about: {
    title: 'O Blance',
    description: 'O Blance.',
    canonical: 'https://prahousblankou.cz/#/about',
  },
  homePage: {
    title: 'Prahou s Blankou',
    description: 'Prahou s Blankou - Komentované procházky historií, které vás pobaví i nadchnou.',
    canonical: 'https://prahousblankou.cz',
  },
  journal: {
    title: 'Žurnál',
    description: 'Prahou s Blankou - Žurnál.',
    canonical: 'https://prahousblankou.cz/#/journal',
  },
  otherTours: {
    title: 'Mimopražské procházky',
    description: 'Prahou s Blankou - Mimopražské procházky.',
    canonical: 'https://prahousblankou.cz/#/tours-other',
  },
  personalisedTours: {
    title: 'Procházky po Praze na míru',
    description: 'Prahou s Blankou - Procházky po Praze na míru.',
    canonical: 'https://prahousblankou.cz/#/tours-personalised',
  },
  pragueClassicalTours: {
    title: 'Klasické pražské procházky',
    description: 'Prahou s Blankou - Klasické pražské procházky.',
    canonical: 'https://prahousblankou.cz/#/tours-prague-classic',
  },
  pragueForChildrenTours: {
    title: 'Procházky po Praze pro děti',
    description: 'Prahou s Blankou - Procházky po Praze pro děti.',
    canonical: 'https://prahousblankou.cz/#/tours-prague-for-children',
  },
  pragueThematicTours: {
    title: 'Tematické procházky po Praze',
    description: 'Prahou s Blankou - Tematické procházky po Praze.',
    canonical: 'https://prahousblankou.cz/#/tours-prague-thematic',
  },
  pragueUndiscoveredTours: {
    title: 'Neobjevená zákoutí Prahy',
    description: 'Prahou s Blankou - Neobjevená zákoutí Prahy.',
    canonical: 'https://prahousblankou.cz/#/tours-prague-undiscovered',
  },
  preparation: {
    title: 'Příprava na procházku',
    description: 'Prahou s Blankou - Příprava na procházku.',
    canonical: 'https://prahousblankou.cz/#/preparation',
  },
  prices: {
    title: 'Ceny procházek',
    description: 'Prahou s Blankou - Ceny procházek.',
    canonical: 'https://prahousblankou.cz/#/prices',
  },
  reservation: {
    title: 'Objednat procházku',
    description: 'Prahou s Blankou - Objednat procházku.',
    canonical: 'https://prahousblankou.cz/#/reservation',
  },
  reviews: {
    title: 'Recenze',
    description: 'Prahou s Blankou - Recenze.',
    // canonical: 'https://prahousblankou.cz/#/tours-prague-classic',
  },
  tourOfTheMonth: {
    title: 'Procházka měsíce',
    description: 'Prahou s Blankou - Procházka měsíce.',
    canonical: 'https://prahousblankou.cz/#/tour-of-the-month',
  },
};
