export const MEETING_POINTS = [
  {
    id: 'NY',
    titleCZ: 'Před budovou New Yorker v ulici Na Příkopě',
    titleEN: 'By the New Yorker shop at the Na Příkopě str.',
  },
  {
    id: 'MS',
    titleCZ: 'Malostranská, u prodejny tabáku',
    titleEN: 'Malostranská, u prodejny tabáku',
  },
  {
    id: 'VN',
    titleCZ: 'Muzeum, u sochy sv. Václava',
    titleEN: 'Muzeum, u sochy sv. Václava',
  },
  {
    id: 'KN',
    titleCZ: 'Socha Karla IV., Křižovnické náměstí',
    titleEN: 'The statue of Charles IV, Křižovnické square',
  },
];
