export const HOME_PAGE_THESES_EN = [
  {
    id: 0,
    title: 'LEARN MORE ABOUT PRAGUE THAN THE HISTORY LESSONS TELL',
    text:
      'Would you like to deepen your knowledge of Prague history and enjoy it’s ' +
      'magical atmosphere? Walk with me through the well known and less known parts of Prague and ' +
      'enrich yourselves with the unique narration about our history. You may ' +
      'refresh your own historical knowledge, while enjoying the magical ' +
      'atmosphere of Prague. And together we can try to unveil some of the many ' +
      'mysteries hidden in this beautiful city.',
    image: '',
    reversed: false,
  },
  {
    id: 1,
    title: 'VIVID AND ENJOYABLE NARRATION',
    text:
      'Forget about dry theory and a long list of boring dates - my speciality ' +
      'is stories. Bloody, funny, spicy - sometimes all of these together. ' +
      'You can look forward to an entertaining experience thanks to which ' +
      'you will have a chance to see our history through your own eyes.',
    image: '',
    reversed: true,
  },
  {
    id: 2,
    title: 'PROFESSIONAL PRAGUE TOUR GUIDE',
    text:
      'I have been working as a professional Prague tour guide for the last 19 ' +
      'years ( for 16 years it has been my full time job). It is both my job and ' +
      'my hobby - as I often say:,,History is not boring if you know how to read ' +
      'it and how to talk about it.” I like to entertain people and to show them ' +
      'our history from a different perspective - with wit, stories and unseen ' +
      '(but important) details. I would be honored to be your guide and to be able ' +
      'to introduce Prague to you as you have not seen it before.',
    image: '',
    reversed: false,
  },
  {
    id: 3,
    title: 'PRAGUE FOR PRAGUERS AND OUTSIDERS',
    text:
      'Doesn’t matter if you are from Letná, Ostrava, London, New York or Sydney, ' +
      'or if you have been to Prague thousand times already - there are always ' +
      'new places and sights to discover. Feel free to choose any of our tours - ' +
      'from the well known locations like the Prague castle or the Jewish Town, ' +
      'through the less known parts as the Strahov monastery to the thematic stories ' +
      '- for example about the naughty ladies of our city.',
    image: '',
    reversed: true,
  },
  {
    id: 4,
    title: 'ESPECIALLY FOR THE YOUNG EXPLORERS',
    text:
      'You can find many ghosts, mythical beings and stories about kings and ' +
      'princesses in Prague. We offer an entertaining tour that may show your ' +
      'children how funny history can be and gives them a chance to become ' +
      'detectives for a day!',
    image: '',
    reversed: false,
  },
  {
    id: 5,
    title: 'PERSONALISED TOURS',
    text:
      'Pamatujete si to místo, kde se vám líbila ta zahrada v centru? ' +
      'A jaký příběh se váže k tomu skvostnému zámečku na kopci? Přejete si ' +
      'prostě Prahu poznat podle svého a vytvořit si vlastní procházku po ' +
      'místech, která vás lákají? I když třeba nejsou součástí mých stálých ' +
      'procházek? Výborně! Velice ráda s vámi naplánuji vaši trasu a zajistím ' +
      'procházku přímo na míru vašich potřeb.',
    image: '',
    reversed: true,
  },
  {
    id: 6,
    title: 'PRAGUE AS A GIFT',
    text:
      'There are many presents you can give to your family and friends. ' +
      'But if you give them an experience, you give them new memories no one ' +
      'can take away. So why not giving them one of our tours as the best present?',
    image: '',
    reversed: true,
  },
];
