import React from 'react';
import {Container, Jumbotron} from 'react-bootstrap';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import DocumentMeta from 'react-document-meta';
import {isEnglishSet, metaTagsCZ, metaTagsEN} from '../shared/utils';

class PersonalisedTours extends React.Component {
  render() {
    const {t} = this.props;
    const metaTags = isEnglishSet() ? metaTagsEN.personalisedTours : metaTagsCZ.personalisedTours;
    window.scrollTo(0, 0);
    return (
      <DocumentMeta {...metaTags}>
        <Container className="mt-5 mb-5 pt-5 pb-5">
          <h3 className="">{t('pages.personalised.title')}</h3>
          {t('pages.personalised.legend1')}
          <br />
          <br />
          {t('pages.personalised.legend2')}
          <br />
          <br />
          <b>{t('pages.personalised.prices')}</b>
          <br />
          {t('pages.personalised.pricesNote')}
          <br />
          <br />
          <Jumbotron>
            <b>{t('pages.personalised.types.individual.title')}</b>
            <br />
            {t('pages.personalised.types.individual.legend')}
            <br />
            <b>{t('pages.personalised.types.individual.price')}</b>
          </Jumbotron>
          <Jumbotron>
            <b>{t('pages.personalised.types.family.title')}</b>
            <br />
            {t('pages.personalised.types.family.legend')}
            <br />
            <b>{t('pages.personalised.types.family.price')}</b>
          </Jumbotron>
          <Jumbotron>
            <b>{t('pages.personalised.types.family2.title')}</b>
            <br />
            {t('pages.personalised.types.family2.legend')}
            <br />
            <b>{t('pages.personalised.types.family2.price')}</b>
          </Jumbotron>
          <Jumbotron>
            <b>{t('pages.personalised.types.group.title')}</b>
            <br />
            {t('pages.personalised.types.group.legend')}
          </Jumbotron>
        </Container>
      </DocumentMeta>
    );
  }
}

PersonalisedTours.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(PersonalisedTours);
