import React from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import PragueBasic from '../assets/images/prague/CharlesBridge.JPG';
import PragueClassical from '../assets/images/prague/Vltava_sm.jpg';
import OldTown from '../assets/images/prague/OldTownSquare.JPG';
import PragueCastle from '../assets/images/prague/PragudeCastle_sm.jpg';
import PragueCastle2 from '../assets/images/prague/PrazskyHrad2_sm.jpg';
import Vysehrad from '../assets/images/prague/Vysehrad.jpg';
import JewishTown from '../assets/images/prague/Synagoga_sm.jpg';
import JewishTown2 from '../assets/images/prague/SpanelskaSynagoga_sm.jpg';
import Popup from 'reactjs-popup';
import TourCardModal from '../components/tourCardModal';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import {TOURS_PRAGUE_EN} from '../shared/TOURS_PRAGUE_EN';
import {TOURS_PRAGUE_CZ} from '../shared/TOURS_PRAGUE_CZ';
import {isEnglishSet, metaTagsCZ, metaTagsEN} from '../shared/utils';
import DocumentMeta from 'react-document-meta';

class PragueClassicalTours extends React.Component {
  render() {
    const {t} = this.props;
    const metaTags = isEnglishSet()
      ? metaTagsEN.pragueClassicalTours
      : metaTagsCZ.pragueClassicalTours;
    const CLASSICAL_PRAGUE = isEnglishSet()
      ? TOURS_PRAGUE_EN.filter((tour) => tour.type === 'classic')
      : TOURS_PRAGUE_CZ.filter((tour) => tour.type === 'classic');
    const CLASSICAL_PRAGUE_IMAGES = [
      PragueBasic,
      PragueClassical,
      OldTown,
      PragueCastle,
      PragueCastle2,
      Vysehrad,
      JewishTown,
      JewishTown2,
    ];
    window.scrollTo(0, 0);
    return (
      <DocumentMeta {...metaTags}>
        <Container className="mt-5 mb-5 pt-5 pb-5">
          <h3 className="">{t('pages.classicalPrague.title')}</h3>
          {t('pages.classicalPrague.legend')}
          <Row noGutters className="pl-2">
            {CLASSICAL_PRAGUE.map((value, index) => {
              return (
                <Col xs={12} md={6} xl={4} className="pt-2 pr-2 mb-2" key={index}>
                  <Popup
                    trigger={
                      <Card className="tour-card">
                        <Card.Img variant="top" src={CLASSICAL_PRAGUE_IMAGES[index]} />
                        <Card.Body>
                          <Card.Title>{value.name}</Card.Title>
                          <Card.Text>{value.description}</Card.Text>
                        </Card.Body>
                        <Card.Footer>
                          <span className="font-weight-bold">
                            {t('widgets.tour_card.duration')}
                          </span>{' '}
                          {value.duration + 'h'}
                          <br />
                          <span className="font-weight-bold">
                            {t('widgets.tour_card.price')}
                          </span>{' '}
                          {value.price}
                        </Card.Footer>
                      </Card>
                    }
                    modal
                    closeOnDocumentClick>
                    <TourCardModal data={value} image={CLASSICAL_PRAGUE_IMAGES[index]} />
                  </Popup>
                </Col>
              );
            })}
          </Row>
        </Container>
      </DocumentMeta>
    );
  }
}

PragueClassicalTours.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(PragueClassicalTours);
