import React from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import ChildTour from '../assets/images/prague/VysehradDeti.jpg';
import CharlesIV from '../assets/images/prague/CharlesIV.jpg';
import Popup from 'reactjs-popup';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import TourCardModal from '../components/tourCardModal';
import {TOURS_PRAGUE_EN} from '../shared/TOURS_PRAGUE_EN';
import {TOURS_PRAGUE_CZ} from '../shared/TOURS_PRAGUE_CZ';
import {isEnglishSet, metaTagsCZ, metaTagsEN} from '../shared/utils';
import DocumentMeta from 'react-document-meta';

class PragueForChildrenTours extends React.Component {
  render() {
    const {t} = this.props;
    const metaTags = isEnglishSet()
      ? metaTagsEN.pragueForChildrenTours
      : metaTagsCZ.pragueForChildrenTours;
    const PRAGUE_FOR_CHILDREN = isEnglishSet()
      ? TOURS_PRAGUE_EN.filter((tour) => tour.type === 'children')
      : TOURS_PRAGUE_CZ.filter((tour) => tour.type === 'children');
    const PRAGUE_FOR_CHILDREN_IMAGES = [ChildTour, CharlesIV, ChildTour];
    window.scrollTo(0, 0);
    return (
      <DocumentMeta {...metaTags}>
        <Container className="mt-5 mb-5 pt-5 pb-5">
          <h3 className="">{t('pages.pragueForChildren.title')}</h3>
          {t('pages.pragueForChildren.legend')}
          <br />
          <br />
          {t('pages.pragueForChildren.notes')}
          <Row noGutters className="pl-2">
            {PRAGUE_FOR_CHILDREN.map((value, index) => {
              return (
                <Col xs={12} md={6} xl={4} className="pt-2 pr-2 mb-2" key={index}>
                  <Popup
                    trigger={
                      <Card className="tour-card">
                        <Card.Img variant="top" src={PRAGUE_FOR_CHILDREN_IMAGES[index]} />
                        <Card.Body>
                          <Card.Title>{value.name}</Card.Title>
                          <Card.Text>{value.description}</Card.Text>
                        </Card.Body>
                        <Card.Footer>
                          <span className="font-weight-bold">
                            {t('widgets.tour_card.duration')}
                          </span>{' '}
                          {value.duration + 'h'}
                          <br />
                          <span className="font-weight-bold">
                            {t('widgets.tour_card.price')}
                          </span>{' '}
                          {value.price}
                        </Card.Footer>
                      </Card>
                    }
                    modal
                    closeOnDocumentClick>
                    <TourCardModal data={value} image={PRAGUE_FOR_CHILDREN_IMAGES[index]} />
                  </Popup>
                </Col>
              );
            })}
          </Row>
        </Container>
      </DocumentMeta>
    );
  }
}

PragueForChildrenTours.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(PragueForChildrenTours);
