import {Container} from 'react-bootstrap';
import React from 'react';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';

class GMap extends React.Component {
  render() {
    const {t, type, isMobile} = this.props;
    let map;
    switch (type) {
      case 'NY':
        map = isMobile ? (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.1016020363345!2d14.4212591156017!3d50.08438457942647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94ec411ee323%3A0xc207b6fc331ebe72!2sNEW%20YORKER!5e0!3m2!1scs!2scz!4v1619961891740!5m2!1scs!2scz"
            title="New Yorker"
            width="100%"
            height="200"
            style={{border: 0}}
            allowFullScreen=""
            loading="lazy"></iframe>
        ) : (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.1016020363345!2d14.4212591156017!3d50.08438457942647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94ec411ee323%3A0xc207b6fc331ebe72!2sNEW%20YORKER!5e0!3m2!1scs!2scz!4v1619961891740!5m2!1scs!2scz"
            title="New Yorker"
            width="100%"
            height="400"
            style={{border: 0}}
            allowFullScreen=""
            loading="lazy"></iframe>
        );
        break;
      case 'MS':
        map = isMobile ? (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5119.753549144258!2d14.403885464873753!3d50.088593935414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94e0d05de20d%3A0xd1bfaaae7127fefc!2sRELAY!5e0!3m2!1scs!2scz!4v1620253336543!5m2!1scs!2scz"
            title="Malostranská"
            width="100%"
            height="200"
            style={{border: 0}}
            allowFullScreen=""
            loading="lazy"></iframe>
        ) : (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5119.753549144258!2d14.403885464873753!3d50.088593935414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94e0d05de20d%3A0xd1bfaaae7127fefc!2sRELAY!5e0!3m2!1scs!2scz!4v1620253336543!5m2!1scs!2scz"
            title="Malostranská"
            width="100%"
            height="400"
            style={{border: 0}}
            allowFullScreen=""
            loading="lazy"></iframe>
        );
        break;
      case 'VN':
        map = isMobile ? (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.347345691333!2d14.427543115886104!3d50.07978332188429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b948d7c50ec4f%3A0x30427619ee0c8bd0!2zUG9tbsOtayBzdmF0w6lobyBWw6FjbGF2YQ!5e0!3m2!1scs!2scz!4v1620253399714!5m2!1scs!2scz"
            title="Václavské náměstí"
            width="100%"
            height="200"
            style={{border: 0}}
            allowFullScreen=""
            loading="lazy"></iframe>
        ) : (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.347345691333!2d14.427543115886104!3d50.07978332188429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b948d7c50ec4f%3A0x30427619ee0c8bd0!2zUG9tbsOtayBzdmF0w6lobyBWw6FjbGF2YQ!5e0!3m2!1scs!2scz!4v1620253399714!5m2!1scs!2scz"
            title="Václavské náměstí"
            width="100%"
            height="400"
            style={{border: 0}}
            allowFullScreen=""
            loading="lazy"></iframe>
        );
        break;
      case 'KN':
        map = isMobile ? (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d639.9996296950081!2d14.413356729245828!3d50.0863145987142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94e5ef06752f%3A0x9e18da4dda923151!2sSocha%20Karla%20IV.!5e0!3m2!1scs!2scz!4v1621097731151!5m2!1scs!2scz"
            title="Křižovnické náměstí"
            width="100%"
            height="200"
            style={{border: 0}}
            allowFullScreen=""
            loading="lazy"></iframe>
        ) : (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d639.9996296950081!2d14.413356729245828!3d50.0863145987142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94e5ef06752f%3A0x9e18da4dda923151!2sSocha%20Karla%20IV.!5e0!3m2!1scs!2scz!4v1621097731151!5m2!1scs!2scz"
            title="Křižovnické náměstí"
            width="100%"
            height="400"
            style={{border: 0}}
            allowFullScreen=""
            loading="lazy"></iframe>
        );
        break;
      default:
        return;
    }
    return <Container>{map}</Container>;
  }
}

GMap.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

export default translate(GMap);
