import {Container} from 'react-bootstrap';
import React from 'react';
import {setLanguage, translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import {Facebook, Instagram, Linkedin, Mail, Map, Phone, Send} from 'react-feather';

class Footer extends React.Component {
  handleSetLanguage = (key) => () => {
    setLanguage(key);
  };

  render() {
    const {t} = this.props;
    return (
      <footer className="footer">
        <Container className="footer-responsive">
          <a href="https://www.facebook.com/prahousblankou" className="footer-ml-5">
            <Facebook />
            <span className="footer-item-hidden"> Facebook</span>
          </a>
          <a href="https://www.instagram.com/prahousblankou" className="footer-ml-5">
            <Instagram />
            <span className="footer-item-hidden"> Instagram</span>
          </a>
          <a href="https://www.linkedin.com/in/blanka-hladka-5618a634" className="footer-ml-5">
            <Linkedin />
            <span className="footer-item-hidden"> Linkedin</span>
          </a>
          <a className="footer-ml-5" href="mailto:blanka.hladka@gmail.com">
            <Mail />
            <span className="footer-item-hidden"> blanka.hladka@gmail.com</span>
          </a>
          <a className="footer-ml-5" href="tel:+420774879511">
            <Phone />
            <span className="footer-item-hidden"> +420 774 879 511</span>
          </a>
          <a className="footer-ml-5" href="https://www.google.com/maps/place/prague">
            <Map />
            <span className="footer-item-hidden"> {t('contact.city')}</span>
          </a>
          <a className="footer-ml-5" href="http://prahousblankou.cz">
            <Send />
            <span className="footer-item-hidden"> prahousblankou.cz</span>
          </a>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(Footer);
