import React from 'react';
import {Container} from 'react-bootstrap';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import {isEnglishSet, metaTagsCZ, metaTagsEN} from '../shared/utils';
import DocumentMeta from 'react-document-meta';

class Magazine extends React.Component {
  render() {
    const {t} = this.props;
    const metaTags = isEnglishSet() ? metaTagsEN.journal : metaTagsCZ.journal;
    window.scrollTo(0, 0);
    return (
      <DocumentMeta {...metaTags}>
        <Container className="mt-5 mb-5 pt-5 pb-5 text-center">
          <h3>{isEnglishSet() ? 'Journal' : 'Žurnál'}</h3>
          <Container className="fb-iframe">
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprahousblankou&tabs=timeline%2C%20events%2C%20messages&width=500&height=520&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width="500"
              height="520"
              title="Facebook iframe"
              style={{border: 'none', overflow: 'hidden'}}
              scrolling="no"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </Container>
        </Container>
      </DocumentMeta>
    );
  }
}

Magazine.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(Magazine);
