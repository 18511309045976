import React from 'react';
import {Container} from 'react-bootstrap';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import DocumentMeta from 'react-document-meta';
import {isEnglishSet, metaTagsCZ, metaTagsEN} from '../shared/utils';

class Preparation extends React.Component {
  render() {
    const {t} = this.props;
    const metaTags = isEnglishSet() ? metaTagsEN.preparation : metaTagsCZ.preparation;
    window.scrollTo(0, 0);
    return (
      <DocumentMeta {...metaTags}>
        <Container className="mt-5 mb-5 pt-5 pb-5">
          <h3>{t('pages.preparation.title')}</h3>
          {t('pages.preparation.legend')}
          <br />
          <br />
          <b>{t('pages.preparation.paragraph1.highlighted')}</b>
          <br />
          {t('pages.preparation.paragraph1.text')}
          <br />
          <br />
          <b>{t('pages.preparation.paragraph2.highlighted')}</b>
          <br />
          {t('pages.preparation.paragraph2.text')}
          <br />
          <br />
          <b>{t('pages.preparation.paragraph3.highlighted')}</b>
          <br />
          {t('pages.preparation.paragraph3.text')}
          <br />
          <br />
          <b>{t('pages.preparation.paragraph4.highlighted')}</b>
          <br />
          {t('pages.preparation.paragraph4.text')}
          <br />
          <br />
          <b>{t('pages.preparation.paragraph5.highlighted')}</b>
          <br />
          {t('pages.preparation.paragraph5.text')}
          <br />
          <br />
        </Container>
      </DocumentMeta>
    );
  }
}

Preparation.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(Preparation);
