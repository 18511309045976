import React from 'react';
import {Col, Container, Image, Jumbotron, Row} from 'react-bootstrap';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import PragueGuideFront from '../assets/images/licences/pragueGuideFront.jpg';
import JewishGuideFront from '../assets/images/licences/jewishGuideFront.jpg';
import DocumentMeta from 'react-document-meta';
import {isEnglishSet, metaTagsCZ, metaTagsEN} from '../shared/utils';

class About extends React.Component {
  render() {
    const {t} = this.props;
    const metaTags = isEnglishSet() ? metaTagsEN.about : metaTagsCZ.about;
    window.scrollTo(0, 0);
    return (
      <DocumentMeta {...metaTags}>
        <Container className="mt-5 mb-5 pt-5 pb-5">
          <h3>{t('pages.about.title')}</h3>
          <b>{t('pages.about.paragraph1.highlighted')}</b>
          {t('pages.about.paragraph1.text')}
          <br />
          <br />
          {t('pages.about.paragraph2.text1')}
          <b>{t('pages.about.paragraph2.highlighted')}</b>
          {t('pages.about.paragraph2.text2')}
          <br />
          <br />
          <b>{t('pages.about.paragraph3.highlighted')}</b>
          <br />
          <br />
          {t('pages.about.paragraph4.text')}
          <b>{t('pages.about.paragraph4.highlighted')}</b>
          <br />
          <br />
          {t('pages.about.paragraph45.text1')}
          <b>{t('pages.about.paragraph45.highlighted')}</b>
          {t('pages.about.paragraph45.text2')}
          <br />
          <br />
          {t('pages.about.paragraph5.text1')}
          <b>{t('pages.about.paragraph5.highlighted1')}</b>
          {t('pages.about.paragraph5.text2')}
          <b>{t('pages.about.paragraph5.highlighted2')}</b>
          {t('pages.about.paragraph5.text3')}
          <b>{t('pages.about.paragraph5.highlighted3')}</b>
          <br />
          <br />
          <i>{t('pages.about.paragraph6.text')}</i> Blanka Hladká
          <br />
          <br />
          <div className="text-center">
            <b>{t('pages.about.paragraph7.highlighted')}</b>
          </div>
          <br />
          <br />
          <b>{t('pages.about.paragraph8.highlighted')}</b>
          <br />
          {t('pages.about.paragraph8.text')}
          <br />
          <br />
          <b>{t('pages.about.paragraph9.highlighted')}</b>
          <br />
          {t('pages.about.paragraph9.text')}
          <br />
          <br />
          <Jumbotron>
            <b>{t('pages.about.paragraph10.highlighted')}</b>
            <br />
            {t('pages.about.paragraph10.text')}
            <br />
            <br />
            <i>
              {t('pages.about.paragraph11.text1')}
              <b> {t('pages.about.paragraph11.highlighted')}</b>
              {t('pages.about.paragraph11.text2')}
            </i>{' '}
            Blanka Hladká
            <br />
            <br />
            {t('pages.about.paragraph12.text')}
            <br />
            <Row className="mt-3">
              <Col xs="12" md="6">
                <Container>
                  <Image src={PragueGuideFront} className="license-photo" fluid />
                </Container>
              </Col>

              <Col xs="12" md="6">
                <Container className="image-container">
                  <Image src={JewishGuideFront} className="license-photo" fluid />
                </Container>
              </Col>
            </Row>
          </Jumbotron>
        </Container>
      </DocumentMeta>
    );
  }
}

About.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(About);
