import React from 'react';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';

class GCalendar extends React.Component {
  render() {
    const {t} = this.props;
    return (
      <iframe
        src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FPrague&amp;src=YmxhbmthLmhsYWRrYUBnbWFpbC5jb20&amp;color=%237986CB&amp;title=Proch%C3%A1zky%2FWalks&amp;showNav=1&amp;showTitle=1&amp;showDate=1&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=0&amp;showTz=1&amp;hl=en_GB"
        width="100%"
        height="600"
        title="Google Calendar iframe"
        frameBorder="0"
        scrolling="no"></iframe>
    );
  }
}

GCalendar.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(GCalendar);
