import React from 'react';
import {Map} from 'react-feather';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBus,
  faChild,
  faCogs,
  faMask,
  faMoneyCheck,
  faSearchLocation,
  faUserAstronaut,
  faWalking,
} from '@fortawesome/free-solid-svg-icons';
import {
  faAddressCard,
  faCalendarCheck,
  faNewspaper,
  faStar,
} from '@fortawesome/free-regular-svg-icons';
import {Button, Image, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {setLanguage, translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import FlagIcon from './flagIcon';
import {isEnglishSet} from '../shared/utils';
import LogoBH from '../assets/images/logo.svg';

class NavBar extends React.Component {
  handleSetLanguage = (key) => () => {
    setLanguage(key);
  };

  render() {
    const {t} = this.props;
    const menuTours = (
      <>
        <Map size="1em" /> {t('menu.tours')}
      </>
    );
    return (
      <Navbar collapseOnSelect expand="lg" fixed="top" className="mb-3 navbar-blue">
        <LinkContainer exact to="/">
          <Nav.Link className="logo-link-wrapper">
            <Navbar.Brand className="logo-link">
              <Image src={LogoBH} alt="Logo" className="logo-image" />
              <span className="logo-text">Prahou s Blankou</span>
            </Navbar.Brand>
          </Nav.Link>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto align-items-center">
            <NavDropdown title={menuTours} id="basic-navbar-nav">
              <LinkContainer exact to="/tours-prague-classic">
                <NavDropdown.Item>
                  <FontAwesomeIcon icon={faWalking} className="menu-icon" />
                  {t('menu.tours-prague-classic')}
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer exact to="/tours-prague-undiscovered">
                <NavDropdown.Item>
                  <FontAwesomeIcon icon={faSearchLocation} className="menu-icon" />
                  {t('menu.tours-prague-undiscovered')}
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer exact to="/tours-prague-thematic">
                <NavDropdown.Item>
                  <FontAwesomeIcon icon={faMask} className="menu-icon" />
                  {t('menu.tours-prague-thematic')}
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer exact to="/tours-prague-for-children">
                <NavDropdown.Item>
                  <FontAwesomeIcon icon={faChild} className="menu-icon" />
                  {t('menu.tours-prague-for-children')}
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer exact to="/tours-personalised">
                <NavDropdown.Item>
                  <FontAwesomeIcon icon={faCogs} className="menu-icon" />
                  {t('menu.tours-personalised')}
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer exact to="/tours-other">
                <NavDropdown.Item>
                  <FontAwesomeIcon icon={faBus} className="menu-icon" />
                  {t('menu.tours-other')}
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer exact to="/tour-of-the-month">
              <Nav.Link>
                <FontAwesomeIcon icon={faStar} className="menu-icon" />
                {t('menu.tour-of-the-month')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="/prices">
              <Nav.Link>
                <FontAwesomeIcon icon={faMoneyCheck} className="menu-icon" />
                {t('menu.prices')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="/preparation">
              <Nav.Link>
                <FontAwesomeIcon icon={faUserAstronaut} className="menu-icon" />
                {t('menu.preparation')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="/journal">
              <Nav.Link>
                <FontAwesomeIcon icon={faNewspaper} className="menu-icon" />
                {t('menu.magazine')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="/about">
              <Nav.Link>
                <FontAwesomeIcon icon={faAddressCard} className="menu-icon" />
                {t('menu.about')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="/reservation">
              <Nav.Link>
                <FontAwesomeIcon icon={faCalendarCheck} className="menu-icon" />
                {t('menu.reservation')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="#">
              <Nav.Link className="hide-on-lg">
                <Button
                  variant="outline-secondary"
                  onClick={this.handleSetLanguage(isEnglishSet() ? 'cz' : 'en')}
                  className="text-nowrap lang-btn">
                  {isEnglishSet() ? <FlagIcon code="cz" /> : <FlagIcon code="gb" />}
                </Button>
              </Nav.Link>
            </LinkContainer>
          </Nav>
          <Button
            variant="outline-secondary"
            onClick={this.handleSetLanguage(isEnglishSet() ? 'cz' : 'en')}
            className="text-nowrap lang-btn show-on-lg">
            {isEnglishSet() ? <FlagIcon code="cz" /> : <FlagIcon code="gb" />}
          </Button>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

NavBar.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(NavBar);
