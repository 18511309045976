import React from 'react';
import {Card, Col, Container, Image, Row} from 'react-bootstrap';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import LogoBH from '../assets/images/logo.svg';
import PragueBasic from '../assets/images/prague/CharlesBridge.JPG';
import PragueClassical from '../assets/images/prague/Vltava_sm.jpg';
import HomePageBeer from '../assets/images/homepage/pivo-image-cropped.svg';
import HomePageGinger from '../assets/images/homepage/ginger-image-cropped.svg';
import HomePageCharlesIV from '../assets/images/homepage/karel-image-cropped.svg';
import HomePageDetective from '../assets/images/homepage/detektiv-image-cropped.svg';
import HomePageAnezka from '../assets/images/homepage/anezka-image-cropped.svg';
import ModernPrague from '../assets/images/prague/Andel_sm.jpg';
import NewTown from '../assets/images/prague/NewTown_sm.jpg';
import RowWithPicture from '../components/rowWithPicture';
import TourOfTheMonthCard from '../components/tourOfTheMonth';
import {HOME_PAGE_THESES_EN} from '../shared/HOME_PAGE_THESES_EN';
import {TOURS_PRAGUE_CZ} from '../shared/TOURS_PRAGUE_CZ';
import {HOME_PAGE_THESES_CZ} from '../shared/HOME_PAGE_THESES_CZ';
import {TOURS_PRAGUE_EN} from '../shared/TOURS_PRAGUE_EN';
import {Link} from 'react-router-dom';
import Popup from 'reactjs-popup';
import TourCardModal from '../components/tourCardModal';
import TourTypesTabs from '../components/tourTypesTabs';
import {isEnglishSet, metaTagsCZ, metaTagsEN} from '../shared/utils';
import DocumentMeta from 'react-document-meta';

class HomePage extends React.Component {
  render() {
    const HOME_PAGE_THESES = isEnglishSet() ? HOME_PAGE_THESES_EN : HOME_PAGE_THESES_CZ;
    const HOME_PAGE_THESES_IMAGES = [
      HomePageBeer,
      HomePageGinger,
      ModernPrague,
      HomePageCharlesIV,
      HomePageDetective,
      HomePageAnezka,
      NewTown,
    ];
    const TOURS_PRAGUE = isEnglishSet() ? TOURS_PRAGUE_EN : TOURS_PRAGUE_CZ;
    const TOURS_PRAGUE_IMAGES = [PragueBasic, PragueClassical, NewTown];
    const {t} = this.props;
    const metaTags = isEnglishSet() ? metaTagsEN.homePage : metaTagsCZ.homePage;
    window.scrollTo(0, 0);
    return (
      <DocumentMeta {...metaTags}>
        <Container fluid className="parallax">
          <Container fluid className="parallax__layer parallax__layer--back" />
          <Container
            fluid
            className="parallax__layer parallax__layer__second parallax__layer--base">
            <Container className="title-row">
              <Image src={LogoBH} alt="Logo" className="title-logo" />
              <Container>
                <Container className="homepage-title">{t('pages.homePage.title')}</Container>
                <Container className="homepage-legend">
                  {t('pages.homePage.legend').toUpperCase()}
                </Container>
              </Container>
            </Container>
            <Container fluid className="bg-white">
              <Container className="homepage-content ">
                {HOME_PAGE_THESES.filter((thesis) => HOME_PAGE_THESES.indexOf(thesis) < 2).map(
                  (value, index) => {
                    return (
                      <RowWithPicture
                        key={index}
                        data={{
                          image: HOME_PAGE_THESES_IMAGES[value.id],
                          data: value,
                        }}
                      />
                    );
                  }
                )}
                <h3 className="mt-5">{t('pages.homePage.favourites')}</h3>
                <Row className="mt-4">
                  {TOURS_PRAGUE.filter((tour) => TOURS_PRAGUE.indexOf(tour) < 3).map(
                    (value, index) => {
                      return (
                        <Col xs={12} md={6} xl={4} className="pt-2 pr-2" key={index}>
                          <Popup
                            disabled={true}
                            trigger={
                              <Card className="tour-card tour-card-static">
                                <Card.Img variant="top" src={TOURS_PRAGUE_IMAGES[index]} />
                                <Card.Body>
                                  <Card.Title>{value.name}</Card.Title>
                                  <Card.Text>{value.description}</Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                  <span className="font-weight-bold">
                                    {t('widgets.tour_card.duration')}
                                  </span>{' '}
                                  {value.duration + 'h'}
                                  <br />
                                  <span className="font-weight-bold">
                                    {t('widgets.tour_card.price')}
                                  </span>{' '}
                                  {value.price}
                                </Card.Footer>
                              </Card>
                            }
                            modal
                            closeOnDocumentClick>
                            <TourCardModal data={value} image={TOURS_PRAGUE_IMAGES[index]} />
                          </Popup>
                        </Col>
                      );
                    }
                  )}
                </Row>
                <Container className="text-center mt-3">
                  <Link to="/tours-prague-classic">{t('pages.homePage.moreTours')}</Link>
                </Container>
                {HOME_PAGE_THESES.filter((thesis) => HOME_PAGE_THESES.indexOf(thesis) === 2).map(
                  (value, index) => {
                    return (
                      <RowWithPicture
                        key={index}
                        data={{
                          image: HOME_PAGE_THESES_IMAGES[value.id],
                          data: value,
                        }}
                      />
                    );
                  }
                )}
                {/*<h3 className="mt-5">{t('pages.homePage.references')}</h3>*/}
                {/*<Row className="mt-4">*/}
                {/*  {REVIEWS.map((value, index) => {*/}
                {/*    return (*/}
                {/*      <Col key={index}>*/}
                {/*        <b>*/}
                {/*          <i>"{value.title}"</i>*/}
                {/*        </b>*/}
                {/*        <br />*/}
                {/*        <i>"{value.review}"</i>*/}
                {/*        <br />*/}
                {/*        <br />*/}
                {/*        {value.name}*/}
                {/*      </Col>*/}
                {/*    );*/}
                {/*  })}*/}
                {/*</Row>*/}
                {HOME_PAGE_THESES.filter(
                  (thesis) =>
                    HOME_PAGE_THESES.indexOf(thesis) > 2 && HOME_PAGE_THESES.indexOf(thesis) <= 5
                ).map((value, index) => {
                  return (
                    <RowWithPicture
                      key={index}
                      data={{
                        image: HOME_PAGE_THESES_IMAGES[value.id],
                        data: value,
                      }}
                    />
                  );
                })}
                <h3 className="mt-5">{t('pages.homePage.types')}</h3>
                <TourTypesTabs />
                <h3 className="mt-5">{t('pages.tourOfTheMonth.title')}</h3>
                <TourOfTheMonthCard showLink={true} />
                {HOME_PAGE_THESES.filter((thesis) => HOME_PAGE_THESES.indexOf(thesis) > 5).map(
                  (value, index) => {
                    return (
                      <RowWithPicture
                        key={index}
                        data={{
                          image: HOME_PAGE_THESES_IMAGES[value.id],
                          data: value,
                        }}
                      />
                    );
                  }
                )}
                <a href="https://info.flagcounter.com/QA4p" className="display-none">
                  <img
                    src="https://s05.flagcounter.com/count2/QA4p/bg_FFFFFF/txt_000000/border_CCCCCC/columns_2/maxflags_10/viewers_0/labels_0/pageviews_0/flags_0/percent_0/"
                    alt="Free counters!"
                    border="0"
                  />
                </a>
              </Container>
            </Container>
          </Container>
        </Container>
      </DocumentMeta>
    );
  }
}

HomePage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(HomePage);
