export const TOURS_PRAGUE_CZ = [
  {
    id: '1',
    name: 'Praha základní',
    legend: 'Kratší procházka po Praze.',
    description: 'Uvidíme Staré město, Židovské město, Karlův most a Pražský hrad.',
    objects: '',
    restrictions:
      'V ceně je krátká přestávka. V ceně nejsou jízdenky MHD ani interiérové vstupenky.',
    images: 'assets/09.jpg',
    duration: '4',
    price: '1600 CZK',
    type: 'classic',
    meetingPoint: 'NY',
  },
  {
    id: '2',
    name: 'Praha klasická',
    legend: 'Procházka Prahou, zahrnující projížďku lodí a oběd.',
    description:
      'Uvidíme Staré město, Židovské město, Karlův most, Pražský hrad a užijete si plavbu na lodi.',
    objects: '',
    restrictions:
      'Do ceny není zahrnuta jízda lodí s nápojem ani oběd.\nV ceně nejsou jízdenky MHD ani interiérové vstupenky.',
    images: 'assets/09.jpg',
    duration: '6',
    price: '2400 CZK',
    type: 'classic',
    meetingPoint: 'NY',
  },
  {
    id: '3',
    name: 'Nové město',
    legend: 'Procházka po Novém městě.',
    description:
      'Projdeme se Václavským náměstím, navštívíme palác Lucerna, Františkánskou zahradu, objevíme skryté uličky Nového města a kolem Tančícího domu projdeme na Karlovo náměstí, kde procházka končí.',
    objects: '',
    restrictions: 'Do ceny je zahrnuta přestávka na oběd.\nV ceně nejsou jízdenky MHD.',
    images: '09.jpg',
    duration: '5',
    price: '2000 CZK',
    type: 'undiscovered',
    meetingPoint: 'VN',
  },
  {
    id: '4',
    name: 'Staré město pražské',
    legend: 'Procházka po Starém městě.',
    description:
      'Uvidíme Staroměstské náměstí, Orloj, Týnský chrám, kostel sv. Mikuláše (menší), Ungelt, kostel sv. Jakuba, staroměstské uličky, klášter sv. Anežky.',
    objects: '',
    restrictions: 'V ceně nejsou jízdenky MHD.',
    images: '../assets/09.jpg',
    duration: '2',
    price: '800 CZK',
    type: 'classic',
    meetingPoint: 'NY',
  },
  {
    id: '5',
    name: 'Pražský hrad bez interiérů',
    legend: 'Procházka Pražským hradem bez vstupu do objektů.',
    description: 'Uvidíme katedrálu Sv. Víta, Starý královský palác a baziliku Sv. Jiří.',
    objects: '',
    restrictions: 'V ceně nejsou jízdenky MHD, interiérové vstupenky.',
    images: '../assets/09.jpg',
    duration: '2',
    price: '800 CZK',
    type: 'classic',
    meetingPoint: 'MS',
  },
  {
    id: '6',
    name: 'Pražský hrad s interiéry',
    legend: 'Procházka Pražským hradem se vstupy do objektů.',
    description:
      'Procházka zahrnuje návštěvu katedrály Sv. Víta, Starého královského paláce, baziliky Sv. Jiří a Zlaté uličky.',
    objects: '',
    restrictions:
      'V ceně nejsou jízdenky MHD, interiérové vstupenky, výstava "Příběh pražského hradu".',
    images: '../assets/09.jpg',
    duration: '4',
    price: '1600 CZK',
    type: 'classic',
    meetingPoint: 'MS',
  },
  {
    id: '7',
    name: 'Vyšehrad',
    legend: 'Procházka druhou nejstarší pražskou pevností.',
    description:
      'Navštívíme chrám sv. Petra a Pavla, Slavín - hřbitov slavných českých osobností a uvidíme místa hluboce spojená s českou historií a legendami.',
    objects: '',
    restrictions:
      'Do ceny nejsou zahrnuty vstupenky do chrámu sv. Petra a Pavla.\nV ceně nejsou jízdenky MHD.',
    images: '../assets/09.jpg',
    duration: '2',
    price: '800 CZK',
    type: 'classic',
    meetingPoint: 'VN',
  },
  {
    id: '8',
    name: 'Malá Strana a Strahovský klášter',
    legend: 'Procházka Malou Stranou a Strahovským klášterem.',
    description:
      'Uvidíme Pohořelec, Strahovskou knihovnu, vyhlídky na Prahu, malostranské kostely, zahrady a paláce.',
    objects: '',
    restrictions:
      'Do ceny nejsou zahrnuty vstupenky do Strahovské knihovny.\nV ceně nejsou jízdenky MHD.',
    images: '../assets/09.jpg',
    duration: '3',
    price: '1200 CZK',
    type: 'undiscovered',
    meetingPoint: 'MS',
  },
  {
    id: '9',
    name: 'Židovské město bez interiérů',
    legend: 'Procházka po Židovském městě.',
    description:
      'Při této procházce uvidíme nejznámější objekty Židovského města (např. Staronovou synagogu, Klausovou synagogu, Pinkasovu synagogu, Starý židovský hřbitov atd.).',
    objects: '',
    restrictions: 'V ceně nejsou jízdenky MHD ani interiérové vstupenky.',
    images: '../assets/09.jpg',
    duration: '2',
    price: '800 CZK',
    type: 'classic',
    meetingPoint: 'NY',
  },
  {
    id: '10',
    name: 'Židovské město s interiéry',
    legend: 'Návštěva synagog a Starého židovského hřbitova.',
    description:
      'Při této procházce navštívíme interiéry nejznámějších synagog pražského Židovského města (např. Staronovou synagogu, Klausovou synagogu, Pinkasovu synagogu) a Starý židovský hřbitov.',
    objects: '',
    restrictions: 'Do ceny nejsou zahrnuty vstupenky do objektů.\nV ceně nejsou jízdenky MHD.',
    images: '../assets/09.jpg',
    duration: '3',
    price: '1200 CZK',
    type: 'classic',
    meetingPoint: 'NY',
  },
  {
    id: '11',
    name: 'Hradčany a Nový Svět',
    legend: 'Procházka Hradčany a Novým Světem s návštěvou Lorety.',
    description: '',
    objects: '',
    restrictions: 'Do ceny nejsou zahrnuty vstupenky do Lorety.\nV ceně nejsou jízdenky MHD.',
    images: '../assets/09.jpg',
    duration: '2',
    price: '800 CZK',
    type: 'undiscovered',
    meetingPoint: 'MS',
  },
  {
    id: '12',
    name: 'Moderní Praha',
    legend: 'Pražská architektura od konce 19. do začátku 21. století.',
    description: '',
    objects: '',
    restrictions: 'V ceně nejsou jízdenky MHD.',
    images: '../assets/09.jpg',
    duration: '4',
    price: '1600 CZK',
    type: 'thematic',
    meetingPoint: 'VN',
  },
  {
    id: '13',
    name: 'Po stopách červených luceren, aneb Hříšná Praha',
    legend: 'Procházka není vhodná pro děti a mládež pod 16 let.',
    description:
      'Procházka uličkami Starého a Židovského města, doprovázená výkladem o životě a práci pražských pouličních slečen a o nejznámějších, nejoblíbenějších, ale i nejhorších pražských veřejných domech. Přidáme i dvě mordýřské historie a pár královských skandálů.',
    objects: '',
    restrictions:
      'Procházka začíná v ulici Na Můstku před prodejnou New Yorker. V ceně nejsou jízdenky MHD.',
    images: '../assets/09.jpg',
    duration: '3',
    price: '1200 CZK',
    type: 'thematic',
    meetingPoint: 'NY',
  },
  {
    id: '14',
    name: '"O Vyšehradských strašidlech, Šemíkovi a Čertově sloupu"',
    legend: 'Procházka pro rodiny s dětmi.',
    description:
      'Procházka po stopách Vyšehradských strašidel, s návštěvou místa, odkud skočil legendární Šemík a kde kněžna Libuše věštila o založení Prahy. Uvidíme i známý Čertův sloup, na který si ti nejodvážnější mohou sáhnout a zkusíme najít svatého Václava, co nestojí na Václavském náměstí.',
    objects: '',
    restrictions:
      'Procházka je vhodná pro děti všech věkových kategorií a trvá dvě hodiny. V ceně nejsou jízdenky MHD.',
    images: '../assets/09.jpg',
    duration: '2',
    price: '800 CZK',
    type: 'children',
    meetingPoint: 'VN',
  },
  {
    id: '15',
    name: 'Výběrové procházky',
    legend: 'Pouze v případě zájmu.',
    description: 'Pražská ZOO, Letohrádek Hvězda, Břevnovský klášter, a jiné...',
    objects: '',
    restrictions: '',
    images: '../assets/09.jpg',
    duration: 'dle typu procházky',
    price: 'dle typu procházky',
  },
  {
    id: '16',
    name: 'Po stopách Karla IV',
    legend:
      'Zábavné odpoledne s dětmi v Praze? Stačí říct a můžeme spolu vyrazit po stopách Karla IV.! Na dvě hodiny se z Vašich dětí stanou detektivové, plnící zadané úkoly. Ten kdo získá nejvíc bodů, získá i zaslouženou odměnu!',
    description:
      'Procházka začíná u sochy Karla IV. na Křižovnickém náměstí, pokračujeme Karlovou ulicí na Mariánské náměstí, Staroměstské náměstí, projdeme Železnou ulicí ke Karlově univerzitě a odtud dojdeme do ulice Na Příkopě, kde procházka končí. Během celé procházky malí detektivové plní úkoly a získávají body. Kdo jich získá nejvíc, dostane na konci procházky zaslouženou výhru.',
    objects: '---',
    restrictions: 'Děti do šesti let zdarma',
    images: '---',
    duration: '2',
    price: '400 CZK/osoba',
    type: 'children',
    meetingPoint: 'KN',
  },
  {
    id: '17',
    name: 'Stromovka královská a výstavní',
    legend: '---',
    description: '---',
    objects: '---',
    restrictions: '---',
    images: '---',
    duration: '---',
    price: '---',
    type: 'children',
    meetingPoint: 'VN',
  },
];
