export const HOME_PAGE_THESES_CZ = [
  {
    id: 0,
    title: 'POZNEJTE PRAHU LÉPE, NEŽ JAK JI ZNÁTE Z DĚJEPISU',
    text:
      'Láká vás prohloubit si základní znalosti pražské historie a pokochat se ' +
      'při tom její magickou atmosférou? Projděte se mnou pražské klasické ' +
      'památky i neznámá zákoutí a obohaťte se jedinečným zábavným vyprávěním, ' +
      'při kterém spolu poodhalíme nejedno tajemství naší bohaté historie.',
    image: '',
    reversed: false,
  },
  {
    id: 1,
    title: 'BARVITÉ VYPRÁVĚNÍ, KTERÉ VÁS NADCHNE',
    text:
      'Na suchou teorii a výčet letopočtů zapoměňte. Mojí specializací jsou ' +
      'příběhy, historky a pikantnosti. Těšte se proto na zážitek, který vás ' +
      'vtáhne do víru děje, zážitek, při kterém budete mít pocit, že vám ' +
      'naše dějiny ožívají přímo před očima.',
    image: '',
    reversed: true,
  },
  {
    id: 2,
    title: 'PROFESIONÁLNÍ PRAŽSKÁ PRŮVODKYNĚ',
    text:
      'Již 19 let pracuji jako lokální profesionální průvodkyně a průvodcování ' +
      'je pro mě posláním, prací i koníčkem zároveň. Baví mě bavit, baví mě ' +
      'vzdělávat a přibližovat naši historii skrze novou perspektivu - s vtipem, ' +
      'pomocí příběhů a nepovšimnutých detailů.. Bude mi ctí, doprovodit vás při ' +
      'procházce našimi městy a představit vám Prahu tak, jak ji ještě neznáte...',
    image: '',
    reversed: false,
  },
  {
    id: 3,
    title: 'PRAHA PRO MÍSTNÍ I PŘESPOLNÍ',
    text:
      'Ať už jste z Letné nebo Ostravy, v Praze byli tisíckrát nebo ještě ani ' +
      'jednou, vždy je tu co objevovat. Vyberte si z procházek od známých ' +
      'lokací, jako je třeba Pražský hrad či Židovské město, přes neobvyklá ' +
      'zákoutí typu okolí Strahovského kláštera až po různá tematická ' +
      'vyprávění, například o hříšných slečnách našeho města.',
    image: '',
    reversed: true,
  },
  {
    id: 4,
    title: 'SPECIÁLNĚ PRO MALÉ OBJEVITELE',
    text:
      'Praha skrývá mnoho strašidel, bájných bytostí a příběhů o králích a ' +
      'princeznách. Vykouzlete dětem zážitek, při kterém se hravou formou ' +
      'seznámí s naší historií a ještě si u toho třeba zahrají na pražské detektivy.',
    image: '',
    reversed: false,
  },
  {
    id: 5,
    title: 'PROCHÁZKY NA MÍRU',
    text:
      'Pamatujete si to místo, kde se vám líbila ta zahrada v centru? ' +
      'A jaký příběh se váže k tomu skvostnému zámečku na kopci? Přejete si ' +
      'prostě Prahu poznat podle svého a vytvořit si vlastní procházku po ' +
      'místech, která vás lákají? I když třeba nejsou součástí mých stálých ' +
      'procházek? Výborně! Velice ráda s vámi naplánuji vaši trasu a zajistím ' +
      'procházku přímo na míru vašich potřeb.',
    image: '',
    reversed: true,
  },
  {
    id: 6,
    title: 'PRAHA JAKO DÁREK',
    text:
      'Svým blízkým můžete darovat všelicos. Ale darovat zážitek, to je jako ' +
      'darovat vzpomínky. A ty nám nikdo a nic nevezme. Darujte komentovanou ' +
      'prohlídku Prahy jako ten nejkrásnější dárek.',
    image: '',
    reversed: true,
  },
];
