import React from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {translate} from 'react-switch-lang';
import PropTypes from 'prop-types';
import GMap from './gmap';
import {MEETING_POINTS} from '../shared/MEETING_POINTS';
import {isEnglishSet} from '../shared/utils';

class TourCardModal extends React.Component {
  render() {
    const {t, data} = this.props;
    const meetingPoint = MEETING_POINTS.find((point) => point.id === data.meetingPoint);
    const meetingPointTitle = isEnglishSet() ? meetingPoint?.titleEN : meetingPoint?.titleCZ;
    return (
      <Container className="popup-container">
        <Card bg="light" text="dark" border="light">
          <Card.Header as="h4" className="modal-title">{data.name}</Card.Header>
          <Card.Body>
            <Row>
              <Col xs={12} lg={data.meetingPoint ? 6 : 12} xl={data.meetingPoint ? 7 : 12}>
                <Card.Title as="h6">{data.legend}</Card.Title>
                <Card.Text>{data.description}</Card.Text>
                {data.meetingPoint ? (
                  <Card.Text>
                    {t('widgets.tour_card.meetingPoint')} {meetingPointTitle}
                  </Card.Text>
                ) : (
                  ''
                )}
              </Col>
              {data.meetingPoint ? (
                <>
                  <Col xs={12} lg={6} xl={5} className="show-on-desktop">
                    <GMap type={data.meetingPoint} />
                  </Col>
                  <Col xs={12} lg={6} xl={5} className="show-on-mobile">
                    <GMap type={data.meetingPoint} isMobile={true} />
                  </Col>
                </>
              ) : (
                ''
              )}
            </Row>
          </Card.Body>
          <Card.Footer>
            {t('widgets.tour_card.duration')} {data.duration} h
            <Link to="/reservation" className="float-right">
              {t('widgets.tour_card.reservation')}
            </Link>
          </Card.Footer>
        </Card>
      </Container>
    );
  }
}

TourCardModal.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default translate(TourCardModal);
