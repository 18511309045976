import React from 'react';
import './App.css';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import Reservation from './pages/reservation';
import HomePage from './pages/homepage';
import NavBar from './components/navBar';
import {setDefaultLanguage, setLanguageCookie, setTranslations} from 'react-switch-lang';
import en from './shared/en.json';
import cz from './shared/cz.json';
import OtherTours from './pages/otherTours';
import Footer from './components/footer';
import {ToastProvider} from 'react-toast-notifications';
import PragueClassicalTours from './pages/pragueClassicalTours';
import PragueUndiscoveredTours from './pages/pragueUndiscoveredTours';
import PragueThematicTours from './pages/pragueThematicTours';
import PragueForChildrenTours from './pages/pragueForChildrenTours';
import PersonalisedTours from './pages/personalisedTours';
import TourOfTheMonth from './pages/tourOfTheMonth';
import Prices from './pages/prices';
import Preparation from './pages/preparation';
import Magazine from './pages/magazine';
import About from './pages/about';

setTranslations({en, cz});
setDefaultLanguage('cz');
setLanguageCookie('language');

function App() {
  return (
    <ToastProvider placement="bottom-right" autoDismiss="true" autoDismissTimeout="10000">
      <Router>
        <NavBar />
        <Switch>
          <Route path="/tours-prague-classic">
            <PragueClassicalTours />
          </Route>
          <Route path="/tours-prague-undiscovered">
            <PragueUndiscoveredTours />
          </Route>
          <Route path="/tours-prague-thematic">
            <PragueThematicTours />
          </Route>
          <Route path="/tours-prague-for-children">
            <PragueForChildrenTours />
          </Route>
          <Route path="/tours-personalised">
            <PersonalisedTours />
          </Route>
          <Route path="/tours-other">
            <OtherTours />
          </Route>
          <Route path="/tour-of-the-month">
            <TourOfTheMonth />
          </Route>
          <Route path="/prices">
            <Prices />
          </Route>
          <Route path="/preparation">
            <Preparation />
          </Route>
          <Route path="/journal">
            <Magazine />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/reservation">
            <Reservation />
          </Route>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="*"></Route>
        </Switch>
        <Footer />
      </Router>
    </ToastProvider>
  );
}

export default App;
